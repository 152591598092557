import { mapGetters } from 'vuex'
import i18n from '@vue-storefront/i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import { CartService } from '@vue-storefront/core/data-resolver';

export const OrderReview = {
  name: 'OrderReview',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isFilled: false,
      orderReview: {
        terms: false,
        gdpr: false,
        biometricData: false
      }
    }
  },
  computed: {
    ...mapGetters({
      isVirtualCart: 'cart/isVirtualCart',
      getShippingDetails: 'checkout/getShippingDetails',
      getPersonalDetails: 'checkout/getPersonalDetails'
    })
  },
  methods: {
    async placeOrder () {
      if (this.getPersonalDetails.createAccount) {
        await this.register()
      } else {
        this.$bus.$emit('checkout-before-placeOrder')
        // resets the remembered delivery method
        await this.$store.dispatch('checkout/saveShippingDetails', {})
        await this.$store.dispatch('checkout/saveReturnShippingDetails', {})
        this.onSuccess()
      }
    },
    async register () {
      const couponCode = await CartService.getCoupon();
      const hasCoupon = await this.$store.getters['cart/getTotals'].find(total => total.code === 'discount')

      const additionalServices = this.$store.getters['additionalServices/getActiveServices']
      this.$bus.$emit('notification-progress-start', i18n.t('Registering the account ...'))
      try {
        const result = await this.$store.dispatch('user/register', {
          email: this.getPersonalDetails.emailAddress,
          password: this.getPersonalDetails.password,
          firstname: this.getPersonalDetails.firstName,
          lastname: this.getPersonalDetails.lastName
        })

        if (result.code !== 200) {
          this.onFailure(result)
          // If error includes a word 'password', emit event that eventually focuses on a corresponding field
          if (result.result.includes(i18n.t('password'))) {
            this.$bus.$emit('checkout-after-validationError', 'password')
          }
          // If error includes a word 'mail', emit event that eventually focuses on a corresponding field
          if (result.result.includes(i18n.t('email'))) {
            this.$bus.$emit('checkout-after-validationError', 'email-address')
          }
          this.$bus.$emit('notification-progress-stop')
        } else {
          this.$bus.$emit('modal-hide', 'modal-signup')
          await this.$store.dispatch('user/login', {
            username: this.getPersonalDetails.emailAddress,
            password: this.getPersonalDetails.password
          })
          await this.$store.dispatch('cart/syncTotals');
          await this.$store.dispatch('additionalServices/setActive', additionalServices);
          if (hasCoupon) {
            await this.$store.dispatch('cart/applyCoupon', couponCode.result);
          }
          this.$bus.$emit('notification-progress-stop')
          this.$bus.$emit('checkout-before-placeOrder', result.result.id)
          this.onSuccess()
        }
      } catch (err) {
        this.$bus.$emit('notification-progress-stop')
        Logger.error(err, 'checkout')()
      }
    }
  }
}
