class ToggleArray {
  private array: any[];

  constructor (array?: any[]) {
    this.array = array || [];
  }

  setArray (array: any[]): void {
    this.array = array;
  }

  getArray (): any[] {
    return this.array;
  }

  hasItem (item: any) {
    return !!this.array.find(i => i === item);
  }

  toggle (item: any): void {
    const itemIndex = this.array.indexOf(item);
    if (itemIndex === -1) {
      this.array.push(item);
    } else {
      this.array.splice(itemIndex, 1);
    }
  }
}

export default ToggleArray;
