import { ActionTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import { MailService } from '@vue-storefront/core/data-resolver';
import MailItem from '@vue-storefront/core/modules/mailer/types/MailItem';

const actions: ActionTree<any, RootState> = {
  sendEmail ({}, mail: MailItem) {
    return MailService.sendEmail(mail);
  }
};

export default actions;
