<template>
  <div class="static-page">
    <div v-if="mode === 'onlyHeader' || mode === 'both'">
      <h2>{{ title }}</h2>
    </div>
    <div v-if="mode === 'onlyBody' || mode === 'both'">
      <div v-for="content in pageContent" :key="content.id">
        <VueShowdown v-if="content.__typename === 'ComponentStaticPageMarkdown'" :markdown="content.Markdown" :options="{ tables: true }" />
        <!-- Add more conditions as needed to handle different content types -->
      </div>
    </div>
  </div>
</template>

<script>
import { VueShowdown } from 'vue-showdown';

export default {
  name: 'StaticPageModalContent',
  components: {
    VueShowdown
  },
  props: {
    mode: {
      type: String,
      default: 'both'
    },
    title: {
      type: String,
      required: true
    },
    pageContent: {
      type: Array,
      required: true
    }
  }
}
</script>
