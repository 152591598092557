<template>
  <button @click="$emit('click')" class="p0 brdr-none nav-button bg-cl-transparent close mr40">
    <svg viewBox="0 0 60 60" width="100%" height="100%">
      <circle style="fill: #fc3711;" cx="156.92" cy="156.92" r="30"
              transform="matrix(1, 0, 0, 1.000001, -126.920029, -126.920174)"
      />
      <path style="stroke: rgb(0, 0, 0); fill: rgb(255, 255, 255); stroke-width: 0px;"
            d="M 10.222 30.98 L 44.805 15.576 L 29.502 50.185 C 28.776 44.586 28.366 38.883 27.639 33.23"
            transform="matrix(0.975819, -0.21858, 0.21858, 0.975819, -6.521717, 6.808983)"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'NavigateButton'
};
</script>

<style scoped>
.nav-button {
  width: 40px;
}
</style>
