<template>
  <pickup-point-modal-window
    @close="$emit('close')"
    :header="$t('Pickup point')"
  >
    <div class="pickup-point-viewer">
      <div class="row pickup-point-viewer-content relative ">
        <div class="pickup-point-info container col-xs-12 col-md-6 h500 overflow-scroll"
             :class="{ 'hidden-xs':mapVisible, 'hidden':mapVisible && mode==='switch', 'col-md-12':mode==='switch' }"
        >
          <pickup-point-info :pickup-point="pickupPoint" />
        </div>
        <div class="col-xs-12 col-md-6 relative pickup-point-map-container h500"
             :class="{ 'hidden-xs':!mapVisible, 'hidden':!mapVisible && mode==='switch', 'col-md-12':mode==='switch' }"
        >
          <navigate-button @click="navigate" class="navigate-button"
                           :class="{'hidden-xs':!mapVisible}"
          />
          <pickup-point-map :pickup-points="[pickupPoint]" />
        </div>
      </div>
      <div class="row pickup-point-bottom-panel hidden">
        <div class="col-xs-12 flex flex-gap-10 button-panel fixed-xs "
             :class="{'button-panel--switch':mode==='switch'}"
        >
          <button-full @click.native="setMapVisible(true)"
                       class="bottom-button"
                       :class="{'bottom-button--white':!mapVisible}"
          >
            {{ $t('Map') }}
          </button-full>
          <button-full @click.native="setMapVisible(false)"
                       class="bottom-button"
                       :class="{'bottom-button--white':mapVisible}"
          >
            {{ $t('List') }}
          </button-full>
        </div>
      </div>
    </div>
  </pickup-point-modal-window>
</template>
<script>
import PickupPointMap from './PickupPointMap';
import ButtonFull from '../../../theme/ButtonFull';
import PickupPointInfo from './PickupPointInfo';
import NavigateButton from '../../NavigateButton';
import PickupPointModalWindow from './PickupPointModalWindow';

export default {
  name: 'PickupPointsViewer',
  components: {
    PickupPointModalWindow,
    PickupPointInfo,
    PickupPointMap,
    ButtonFull,
    NavigateButton
  },
  props: {
    pickupPoint: {
      type: Object,
      required: true
    },
    mode: {
      type: String
    }
  },
  data () {
    return {
      mapVisible: true
    };
  },
  methods: {
    navigate () {
      const url = `https://www.google.com/maps/search/${this.pickupPoint.latitude},${this.pickupPoint.longitude}`;
      window.location.href = url;
    },
    setMapVisible (visible) {
      this.mapVisible = visible;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-silver: color(silver);

.button-panel {
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
}

.button-panel--switch {
  display: flex;
  position: initial;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
}

.h500 {
  height: 500px;
}

.hidden {
  display: none;
}

.pickup-point-viewer {
  height: 100%;
  width: 100%;
}

.pickup-point-bottom-panel {
  height: 50px;
  width: 100%;
}

.pickup-point-viewer-content {
  height: calc(100% - 50px);
}

.bottom-button::v-deep .wrap {
  width: 100px;
  color: white;
}

.bottom-button--white::v-deep .wrap {
  background-color: $color-silver;
}

.overflow-scroll {
  overflow: scroll;
}

.bottom-button {
  min-width: unset;
  width: unset;
  padding-left: 0;
  padding-right: 0;
}

.flex-gap-10 {
  gap: 10px
}

.pickup-point-info {
  padding-right: 20px;
  padding-left: 30px;
  padding-top: 10px;
}

.navigate-button {
  position: absolute;
  z-index: 2000;
  top: 90px;
  left: 11px;
}

@media (max-width: 768px) {
  .pickup-point-bottom-panel {
    display: flex;
  }
  .pickup-point-map-container {
    width: 100%;
  }
}

@media (max-width: 572px) {
  .fixed-xs {
    position: fixed;
  }
  .pickup-point-map-container {
    height: 100%;
  }
}

</style>
