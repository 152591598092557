<template>
  <div class="pt20">
    <pickup-point-detail-modal :pickup-point="pickupPoint" @close="setPickupPoint(null)" v-if="pickupPoint" />
    <div class="row form-step__heading">
      <div
        class="number-circle lh35 cl-white brdr-circle align-center weight-700"
        :class="{ 'bg-cl-th-accent' : isActive || isFilled, 'bg-cl-tertiary' : !isFilled && !isActive }"
      >
        4
      </div>
      <div class="col-xs-10 col-sm-9 col-md-10 checkout-step-info-container">
        <div class="row mb15">
          <div class="col-xs-12 col-md-7" :class="{ 'cl-bg-tertiary' : !isFilled && !isActive }">
            <h3 class="m0 lh35">
              {{ $t('Return') }}
            </h3>
          </div>
          <div class="col-xs-12 col-md-5">
            <div class="lh35 flex end-lg edit-section-container" v-if="isFilled && !isActive">
              <a href="#" class="cl-tertiary flex edit-section-button" @click.prevent="edit('returnShipping')">
                <span class="pr5">
                  {{ $t('Edit return shipping') }}
                </span>
                <i class="material-icons cl-tertiary">edit</i>
              </a>
            </div>
          </div>
        </div>
        <div class="row" v-if="!isActive && isFilled">
          <div class="col-xs-12 col-sm-9 col-md-11">
            <div class="row fs16 mb35">
              <div class="col-xs-12 h4" data-testid="shippingAddressSummary">
                <p v-if="!temporaryBlock">
                  {{ returnShipping.firstName }} {{ returnShipping.lastName }}
                </p>
                <p v-if="!temporaryBlock">
                  {{ returnShipping.streetAddress }} {{ returnShipping.apartmentNumber }}
                </p>
                <p v-if="!temporaryBlock">
                  {{ returnShipping.city }} {{ returnShipping.zipCode }}
                </p>
                <p v-if="!temporaryBlock">
                  <span v-if="returnShipping.state">{{ returnShipping.state }}, </span>
                  <span>{{ getCountryName() }}</span>
                </p>
                <div v-if="!temporaryBlock">
                  <span class="pr15">{{ returnShipping.phoneNumber }}</span>
                  <tooltip>{{ $t('Phone number may be needed by carrier') }}</tooltip>
                </div>
                <div class="col-xs-12">
                  <h4>
                    {{ $t('Return delivery method') }}
                  </h4>
                </div>
                <div class="col-md-6 mb15">
                  <label :class="['radioStyled', 'radioStyled__disabled']">
                    {{ getShippingMethod().method_title || '-' }} | {{ (getShippingMethod().price_incl_tax || '- Kč') | price(storeView) }}
                    <input type="radio" value="" checked disabled name="chosen-return-shipping-method">
                    <span class="checkmark" />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="isActive">
      <div class="col-xs-11 col-sm-9 col-md-10">
        <div class="row mb40 xspl40">
          <h4 class="col-xs-12">
            {{ $t('Return delivery method') }}
          </h4>
          <div v-for="(method, index) in returnShippingMethods" :key="index" class="col-md-6">
            <label class="radioStyled">
              {{ method.method_title || '-' }} | {{ (method.price_incl_tax || '- Kč') | price(storeView) }}
              <input
                type="radio"
                :value="method.method_id"
                name="return-shipping-method"
                v-model="returnShipping.shippingMethod"
                @change="$v.returnShipping.shippingMethod.$touch(); updateShippingMethod();"
              >
              <button v-if="hasPickupPoint(method)" @click="setPickupPoint(method)" class="brdr-none bg-cl-transparent close pl15">
                <i class="material-icons cl-accent info-icon">info_outline</i>
              </button>
              <span class="checkmark" />
            </label>
          </div>
          <span class="validation-error" v-if="$v.returnShipping.shippingMethod.$error && !$v.returnShipping.shippingMethod.required">
            {{ $t('Field is required') }}
          </span>
        </div>
        <div class="row xspl20">
          <transition name="fade">
            <div v-if="currentShippingMethod && currentShippingMethod.needs_address">
              <h4>
                {{ $t('Where to pick up your products?') }}
              </h4>
              <base-checkbox
                v-if="currentUser && hasShippingDetails()"
                class="col-xs-12 mb10"
                id="shipToMyAddressCheckbox"
                v-model="shipToMyAddress"
                @change="changeAddresses({$event, target:'myAddress'})"
              >
                {{ $t('Ship to my default address') }}
              </base-checkbox>

              <base-checkbox
                class="col-xs-12 mb20"
                id="shipToMyPreviousAddressCheckbox"
                v-model="shipToMyPreviousAddress"
                @change="changeAddresses({$event, target:'previousAddress'})"
              >
                {{ $t('Use the address from previous step') }}
              </base-checkbox>

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="first-name"
                :placeholder="$t('First name *')"
                v-model.trim="returnShipping.firstName"
                @blur="$v.returnShipping.firstName.$touch()"
                autocomplete="given-name"
                :validations="[
                  {
                    condition: $v.returnShipping.firstName.$error && !$v.returnShipping.firstName.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.returnShipping.firstName.minLength,
                    text: $t('Name must have at least 2 letters.')
                  }
                ]"
              />

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="last-name"
                :placeholder="$t('Last name *')"
                v-model.trim="returnShipping.lastName"
                @blur="$v.returnShipping.lastName.$touch()"
                autocomplete="family-name"
                :validations="[{
                  condition: $v.returnShipping.lastName.$error && !$v.returnShipping.lastName.required,
                  text: $t('Field is required')
                }]"
              />

              <base-input
                class="col-xs-12 mb10"
                type="text"
                name="street-address"
                :placeholder="$t('Street name *')"
                v-model.trim="returnShipping.streetAddress"
                @blur="$v.returnShipping.streetAddress.$touch()"
                autocomplete="address-line1"
                :validations="[{
                  condition: $v.returnShipping.streetAddress.$error && !$v.returnShipping.streetAddress.required,
                  text: $t('Field is required')
                }]"
              />

              <base-input
                class="col-xs-12 mb10"
                type="text"
                name="apartment-number"
                :placeholder="$t('House/Apartment number *')"
                v-model.trim="returnShipping.apartmentNumber"
                @blur="$v.returnShipping.apartmentNumber.$touch()"
                autocomplete="address-line2"
                :validations="[{
                  condition: $v.returnShipping.apartmentNumber.$error && !$v.returnShipping.apartmentNumber.required,
                  text: $t('Field is required')
                }]"
              />

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="city"
                :placeholder="$t('City *')"
                v-model.trim="returnShipping.city"
                @blur="$v.returnShipping.city.$touch()"
                autocomplete="address-level2"
                :validations="[
                  {
                    condition: $v.returnShipping.city.$error && !$v.returnShipping.city.required,
                    text: $t('Field is required')
                  }/* ,
                  {
                    condition: !$v.returnShipping.city.matchValidator,
                    text: $t('Currently we can not deliver anywhere else than to Prague')
                  } */
                ]"
              />

              <!-- <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="state"
                :placeholder="$t('State / Province')"
                v-model.trim="returnShipping.state"
                autocomplete="address-level1"
              /> -->

              <base-input
                class="col-xs-12 col-sm-6 mb10"
                type="text"
                name="zip-code"
                :placeholder="$t('Zip-code *')"
                v-model.trim="returnShipping.zipCode"
                @blur="$v.returnShipping.zipCode.$touch()"
                autocomplete="postal-code"
                :validations="[
                  {
                    condition: $v.returnShipping.zipCode.$error && !$v.returnShipping.zipCode.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.returnShipping.zipCode.minLength,
                    text: $t('Name must have at least 3 letters.')
                  }
                ]"
              />
              <!-- changeCountry check missing here TODO -->
              <base-select
                class="col-xs-12 col-sm-6 mb10"
                name="countries"
                :options="countryOptions"
                :selected="returnShipping.country"
                :placeholder="$t('Country *')"
                :validations="[
                  {
                    condition: $v.returnShipping.country.$error && !$v.returnShipping.country.required,
                    text: $t('Field is required')
                  }
                ]"
                v-model="returnShipping.country"
                autocomplete="country-name"
                @blur="$v.returnShipping.country.$touch()"
                @change.native="$v.returnShipping.country.$touch(); "
              />

              <base-input
                class="col-xs-12 mb10 pt15"
                type="text"
                name="phone-number"
                :placeholder="$t('Phone Number *')"
                :validations="[
                  {
                    condition: $v.returnShipping.phoneNumber.$error && !$v.returnShipping.phoneNumber.required,
                    text: $t('Field is required')
                  },
                  {
                    condition: !$v.returnShipping.phoneNumber.phoneValidator,
                    text: $t('Please insert phone number in correct format, using numbers')
                  }
                ]"
                @blur="$v.returnShipping.phoneNumber.$touch()"
                v-model.trim="returnShipping.phoneNumber"
                autocomplete="tel"
              />
              <!-- <base-checkbox
                class="col-xs-12 mb10"
                id="returnDeliveryAddressCheckBox"
                v-model="isReturnDelivery"
              >
                {{ $t('I want to return my equipment elsewhere') }}
              </base-checkbox> -->
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="row btn-row" v-if="isActive">
      <div>
        <div class="row">
          <div>
            <!-- <flex-button
              data-testid="shippingSubmit"
              @click.native="sendDataToCheckout()"
              :disabled="isShippingInvalid || shippingMethods.length <= 0"
            >
            {{ $t('Continue to additional services') }}
            </flex-button> -->
            <button-full
              data-testid="shippingSubmit"
              @click.native="sendDataToCheckout()"
              :disabled="isReturnShippingInvalid()"
              wrap-style="button-full-wrap-partner"
            >
              {{ $t('Continue to payment') }}
            </button-full>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, numeric } from 'vuelidate/lib/validators'
import { unicodeAlpha, unicodeAlphaNum } from '@vue-storefront/core/helpers/validators'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseSelect from 'theme/components/core/blocks/Form/BaseSelect'
import Tooltip from 'theme/components/core/Tooltip'
import { mapState, mapGetters } from 'vuex'
import ButtonFull from 'theme/components/theme/ButtonFull'
import PickupPointDetailModal from '../PickupPoint/PickupPointDetailModal';

const Countries = require('@vue-storefront/i18n/resource/countries.json')

// validator extension
export const partialMatchValidator = (stringToMatch) => {
  return (value) => {
    if (value === undefined) {
      return false
    }
    return value.toLowerCase().startsWith(stringToMatch.toLowerCase())
  }
}

const phoneValidator = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false
  }
  return /(?=^\+)(^\+[0-9]{9,12}$)|(^[0-9]{9}$)/.test(value)
}

export default {
  name: 'ReturnShipping',
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    Tooltip,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    ButtonFull,
    PickupPointDetailModal
  },
  data () {
    return {
      isFilled: false,
      temporaryBlock: true,
      equipmentDelivery: false,
      pickupPoint: undefined,
      returnShipping: {},
      countries: Countries,
      shipToMyAddress: false,
      shipToMyPreviousAddress: false,
      myAddressDetails: {
        firstname: '',
        lastname: '',
        country: '',
        region: '',
        city: '',
        street: ['', ''],
        postcode: '',
        telephone: ''
      },
      defaultCountry: null
    }
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$off('checkout-before-shippingMethods', this.onBeforeShippingMethods)
  },
  beforeMount () {
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$on('checkout-before-shippingMethods', this.onBeforeShippingMethods)
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user.current
    }),
    ...mapGetters({
      shippingMethods: 'checkout/getShippingMethods',
      getReturnShippingDetails: 'checkout/getReturnShippingDetails',
      getShippingDetails: 'checkout/getShippingDetails'
    }),
    countryOptions () {
      return this.countries.map((item) => {
        return {
          value: item.code,
          label: item.name
        }
      })
    },
    storeView () {
      return currentStoreView()
    },
    returnShippingMethods () {
      if (!this.shippingMethods) {
        return [{
          method_title: '',
          price_incl_tax: ''
        }]
      }
      return this.shippingMethods
    },
    currentShippingMethod () {
      if (!this.shippingMethods && !this.returnShipping.shippingMethod) {
        this.notifyNoReturnShippingMethods();
        return [{
          method_title: '',
          price_incl_tax: ''
        }]
      }
      return this.shippingMethods.find(method => method.method_id === this.returnShipping.shippingMethod);
    },
    currentShippingMode () {
      return this.getCurrentShippingMethod()
    },
    pickupPoints () {
      const partners = this.$store.getters['partner/getPartners'];
      return partners.map(partner =>
        partner.available_shipping_methods
          .filter(sm => sm.is_pickup_point)
          .map(sm => ({
            methodId: sm.id,
            description: sm.description,
            partnerName: partner.name,
            dropOffDescription: sm.dropoff_description,
            ...sm.pickup_point_info
          }))).reduce((partner, acc) => [...acc, ...partner], []);
    }
  },
  mounted () {
    // this.returnShipping = this.getReturnShippingDetails;
    this.checkDefaultShippingAddress()
    this.checkDefaultShippingMethod()
  },
  watch: {
    shippingMethods: {
      handler () {
        this.checkDefaultShippingMethod()
      }
    }
  },
  methods: {
    setPickupPoint (method) {
      if (!method) {
        this.pickupPoint = undefined;
      }
      const pickupPoint = this.pickupPoints
        .filter(pp => !!pp && pp.methodId)
        .find(pp => +pp.methodId === +method.method_id);

      if (pickupPoint) {
        this.pickupPoint = pickupPoint;
      }
    },
    hasPickupPoint (method) {
      return method && !!this.pickupPoints.find(pp => +pp.methodId === +method.method_id);
    },
    checkDefaultShippingMethod () {
      if (!this.returnShipping.shippingMethod || this.notInMethods(this.returnShipping.shippingMethod)) {
        let shipping = this.shippingMethods.find(item => item.default_address)
        if (!shipping && this.shippingMethods && this.shippingMethods.length > 0) { // asigns the first shipping method available.
          shipping = this.shippingMethods[0]
        }
        // we want users to check their option, so no pre-load
        this.returnShipping = {
          country: this.defaultCountry,
          shippingMethod: shipping.method_id,
          shippingCarrier: shipping.carrier_code
        }
      }
    },
    notInMethods (method) {
      const availableMethods = this.shippingMethods
      if (availableMethods.find(item => item.method_id === method)) {
        return false
      }
      return true
    },
    updateShippingMethod () {
      const currentShippingMethod = this.getCurrentShippingMethod()
      this.$store.dispatch('checkout/saveReturnShippingDetails', this.returnShipping)
      if (currentShippingMethod) {
        this.shipping = Object.assign(this.shipping, {shippingCarrier: currentShippingMethod.carrier_code})
        this.$bus.$emit('checkout-after-returnShippingDetailsChange', {
          country: this.shipping.country,
          method_code: currentShippingMethod.method_code,
          carrier_code: currentShippingMethod.carrier_code,
          payment_method: this.paymentMethod[0].code,
          method_id: currentShippingMethod.method_id
        })
      }
    },
    checkDefaultShippingAddress () {
      // ?? this was the original logic. weird.
      // this.shipToMyAddress = this.hasShippingDetails()
      return this.hasShippingDetails()
    },
    hasShippingDetails () {
      if (this.currentUser) {
        if (this.currentUser.hasOwnProperty('default_shipping')) {
          const id = this.currentUser.default_shipping
          const addresses = this.currentUser.addresses
          for (const address of addresses) {
            const parsedId = parseInt(address.id)
            if (parsedId === id) {
              this.myAddressDetails = address
              return true
            }
          }
        }
      }
      return false
    },
    sendDataToCheckout () {
      this.$store.dispatch('checkout/saveReturnShippingDetails', this.returnShipping)
      this.$bus.$emit('checkout-after-returnShippingDetails')
      this.isFilled = true
    },
    edit () {
      if (this.isFilled) {
        this.$bus.$emit('checkout-before-edit', 'returnShipping')
      }
    },
    changeAddresses (change) {
      // reset
      this.shipToMyPreviousAddress = false
      this.shipToMyAddress = false
      // change
      if (change.target === 'myAddress') {
        this.shipToMyAddress = change.$event
        this.copyAddress(change)
      } else if (change.target === 'previousAddress') {
        this.shipToMyPreviousAddress = change.$event
        this.copyAddress(change)
      }
    },
    //
    copyAddress (change) {
      // copy data from profile
      if (change.$event && change.target === 'myAddress') {
        this.returnShipping = {
          firstName: this.myAddressDetails.firstname,
          lastName: this.myAddressDetails.lastname,
          country: this.myAddressDetails.country_id,
          state: '', // not used anyway
          city: this.myAddressDetails.city,
          streetAddress: this.myAddressDetails.street[0],
          apartmentNumber: this.myAddressDetails.street[1],
          zipCode: this.myAddressDetails.postcode,
          phoneNumber: this.myAddressDetails.telephone,
          shippingMethod: this.currentShippingMode.method_id,
          shippingCarrier: this.currentShippingMode.carrier_code
        }
        // copy data from previous step
      } else if (change.$event && change.target === 'previousAddress') {
        this.returnShipping = {
          firstName: this.getShippingDetails.firstName,
          lastName: this.getShippingDetails.lastName,
          country: this.getShippingDetails.country,
          state: '', // not used anyway
          city: this.getShippingDetails.city,
          streetAddress: this.getShippingDetails.streetAddress,
          apartmentNumber: this.getShippingDetails.apartmentNumber,
          zipCode: this.getShippingDetails.zipCode,
          phoneNumber: this.getShippingDetails.phoneNumber,
          shippingMethod: this.currentShippingMode.method_id,
          shippingCarrier: this.currentShippingMode.carrier_code
        }
        // resets to delivery
      } else {
        this.returnShipping = {
          firstName: '',
          lastName: '',
          country: '',
          state: '', // not used anyway
          city: '',
          streetAddress: '',
          apartmentNumber: '',
          zipCode: '',
          phoneNumber: '',
          shippingMethod: this.currentShippingMethod.method_id,
          shippingCarrier: ''
        }
      }
      // TODO fix later -> for now it's covered by the delivery adress.
      // this.changeCountry()
    },
    getCurrentShippingMethod () {
      const shippingMethodId = this.returnShipping.shippingMethod
      const currentMethod = this.shippingMethods ? this.shippingMethods.find(item => item.method_id === shippingMethodId) : {}
      return currentMethod
    },
    getShippingMethod () {
      for (const method of this.shippingMethods) {
        if (method.method_id === this.returnShipping.shippingMethod) {
          return {
            drop_off_method_title: method.drop_off_method_title,
            method_title: method.method_title,
            price_incl_tax: method.price_incl_tax
          }
        }
      }
      return {
        method_title: '',
        price_incl_tax: ''
      }
    },
    isReturnShippingInvalid () {
      if (this.$v.returnShipping.$invalid && this.currentShippingMethod && this.currentShippingMethod.needs_address) {
        return true
      }
      return false
    },
    onCheckoutLoad () {
      if (this.$store.state.checkout.returnShippingDetails) {
        this.returnShipping = this.$store.state.checkout.returnShippingDetails
      }
    },
    onBeforeShippingMethods (country) {
      this.defaultCountry = country
      this.returnShipping.country = country
    },
    notifyNoReturnShippingMethods () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t('There is no return shipping method currently available.'),
        action1: { label: this.$t('OK') }
      })
    }
  },
  validations: {
    returnShipping: {
      firstName: {
        required,
        minLength: minLength(2),
        unicodeAlpha
      },
      lastName: {
        required,
        unicodeAlpha
      },
      country: {
        required
      },
      streetAddress: {
        required,
        unicodeAlphaNum
      },
      apartmentNumber: {
        required,
        unicodeAlphaNum
      },
      shippingMethod: {
        required,
        unicodeAlphaNum
      },
      zipCode: {
        required,
        minLength: minLength(3),
        unicodeAlphaNum
      },
      phoneNumber: {
        required,
        phoneValidator
      },
      city: {
        required,
        unicodeAlpha
        /* matchValidator: partialMatchValidator('Praha') */
      }
    }
  }
}
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
