
























































































































































import { required } from 'vuelidate/lib/validators'
import { isServer } from '@vue-storefront/core/helpers'
import Composite from '@vue-storefront/core/mixins/composite'
import VueOfflineMixin from 'vue-offline/mixin'
import { mapGetters } from 'vuex'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'

import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import CartSummary from 'theme/components/core/blocks/Checkout/CartSummary.vue'
import Modal from 'theme/components/core/Modal.vue'
import StaticPageModalContent from 'theme/components/theme/anyrent/Static/Partners/StaticPageModalContent.vue'

import { OrderReview } from '@vue-storefront/core/modules/checkout/components/OrderReview'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { registerModule } from '@vue-storefront/core/lib/modules'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import config from 'config';
import STATIC_PAGE_QUERY from 'src/themes/default/graphql/strapi/queries/staticPage.gql';
import { Logger } from '@vue-storefront/core/lib/logger';
import { ModalType } from 'src/constants/ModalTypeToSlugMapping'

const transformStaticPageQueryResponse = (staticPageQueryResponse) => {
  if (!staticPageQueryResponse || !staticPageQueryResponse.data || !staticPageQueryResponse.data.staticPages || !staticPageQueryResponse.data.staticPages.data || !staticPageQueryResponse.data.staticPages.data[0]) {
    Logger.error('No static page data found in response:', staticPageQueryResponse);
    return {};
  }

  const {
    attributes: {
      Title,
      PageContent
    }
  } = staticPageQueryResponse.data.staticPages.data[0];

  return {
    title: Title,
    pageContent: PageContent
  };
};

export default {
  components: {
    BaseCheckbox,
    ButtonFull,
    CartSummary,
    Modal,
    StaticPageModalContent
  },
  data () {
    return {
      goPayUrl: '#404',
      goPaySignature: 'unknown',
      modalContent: [], // To store the fetched content
      modalTitle: '', // To store the title of the modal
      modalType: '', // To store the type of the modal
      ModalType, // Exposing the ModalType Enum for template usage
      loading: false // To track the loading state
    }
  },
  mixins: [OrderReview, Composite, VueOfflineMixin],
  validations: {
    orderReview: {
      terms: {
        required
      },
      gdpr: {
        ...(config.partnerData.displayGdprCheckbox ? { required: required } : {})
      },
      biometricData: {
        ...(config.partnerData.isBiometricDataEnabled ? { required: required } : null)
      }
    }
  },
  beforeCreate () {
    registerModule(OrderModule)
  },
  computed: {
    ...mapGetters({
      payment: 'checkout/getPaymentDetails',
      productsInCart: 'cart/getCartItems'
    })
  },
  beforeMount () {
    EventBus.$on('gopay-go-fly', this.onGopay)
    EventBus.$on('setup-gopay-form', this.onSetupGoPay)
    EventBus.$on('setup-gopay-form-failed', this.paymentInitFailed)
  },
  beforeDestroy () {
    EventBus.$off('gopay-go-fly', this.onGopay)
    EventBus.$off('setup-gopay-form', this.onSetupGoPay)
    EventBus.$off('setup-gopay-form-failed', this.paymentInitFailed)
    console.log('%c calling beforeDestroy on component', 'color:#222222')
  },
  methods: {
    displayPartnerCheckbox () {
      return config.partnerData.displayPartnerPages;
    },
    displayAgreeProcessingBiometricDataCheckbox () {
      return config.partnerData.isBiometricDataEnabled;
    },
    displayAgreeToGdprCheckbox () {
      return config.partnerData.displayGdprCheckbox;
    },
    onSetupGoPay (result) {
      this.goPayUrl = result.gw_url
      // this.goPaySignature = result.signature
      this.$refs.form.action = result.gw_url
    },
    onGopay () {
      try {
        EventBus.$emit('notification-progress-start', this.$t('redirecting to payment...'))
      } catch (err) {
        console.log(' failure in notification triggered by eventbus')
        console.error(err)
      }
      this.$refs.form.submit()
    },
    onSuccess () {
      if (!isServer) {
        window.scrollTo(0, 0);
      }
    },
    paymentInitFailed (result) {
      console.error('payment init failed')
      console.error(result)
      try {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'error',
          message: result,
          action1: {label: 'OK', action: this.redirectAway}
        })
      } catch (err) {
        console.log('failed notification in orderview 2')
        console.error(err)
      }
      // timeout
      setTimeout(() => {
        this.redirectAway()
      }, 4000)
    },
    redirectAway () {
      EventBus.$emit('notification-progress-stop')
      this.$router.push(localizedRoute({ name: 'home' }, currentStoreView().storeCode))
    },
    onFailure (result) {
      console.error('failure in order review 2')
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      })
    },
    checkedOutItems () {
      const items = []
      for (const item of this.productsInCart) {
        const checkoutItem = {
          name: item.name,
          slug: item.slug,
          sku: item.sku
        }
        items.push(checkoutItem)
      }
      return items
    },
    async fetchModalContentAndOpenModal (type: ModalType) {
      const slug = this.getSlugForType(type);
      // Validate that the type is valid and has a corresponding slug
      if (!slug) {
        console.error(`Invalid modal type provided: ${type}`);
        return;
      }

      this.loading = true;
      this.modalType = type;
      try {
        const partnerId = config.partnerData.id;
        const response = await this.$apollo.query({
          query: STATIC_PAGE_QUERY,
          variables: {
            filters: {
              Slug: { eq: slug },
              Partner: { Name: { eq: partnerId } }
            }
          }
        });

        if (!response.data.staticPages.data.length) {
          Logger.error('No content found for slug:', slug);
          return;
        }

        const staticPage = transformStaticPageQueryResponse(response);
        this.modalContent = staticPage.pageContent;
        this.modalTitle = staticPage.title;

        this.loading = false;
        this.$bus.$emit('modal-toggle', `modal-${type}`);
      } catch (error) {
        this.loading = false;
        Logger.error('Failed to fetch modal content:', error);
      }
    },
    getSlugForType (modalType: ModalType): string {
      return config.partnerData.modalTypeToSlugMapping[modalType] || '';
    }
  }
}
