<template>
  <div v-if="isVisible" class="loader-container bg-cl-secondary absolute">
    <div class="loader-inner-container absolute">
      <Spinner />
    </div>
  </div>
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  name: 'LoaderOverlay',
  data () {
    return {
      isVisible: false
    }
  },
  components: {
    Spinner
  },
  beforeMount () {
    this.$bus.$on('loader-overlay-change', this.changeVisibility);
  },
  beforeDestroy () {
    this.$bus.$off('loader-overlay-change', this.changeVisibility);
  },
  methods: {
    changeVisibility (isVisible) {
      this.isVisible = isVisible;
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/base/global_vars';
@import '~theme/css/helpers/functions/color';

$z-index-loader: map-get($z-index, loader);
$bg-cl-secondary: color(secondary,$colors-background);

.loader-inner-container {
  left: 50%;
  top: 50%;
}
.loader-container {
    z-index: $z-index-loader;
    background-color: grey;
    opacity: 0.1;
    inset: 0;
  }
  .justify-content-center {
  justify-content: center;
}
</style>
