




import CheckoutAnyrent from './CheckoutAnyrent.vue';
import CheckoutSbcr from './CheckoutSbcr.vue';
import CheckoutHighlandsport from './CheckoutHighlandsport.vue';
import config from 'config'

export default {
  components: {
    CheckoutAnyrent,
    CheckoutSbcr,
    CheckoutHighlandsport
  },
  methods: {
    partnerId () {
      return this.$ssrContext?.partnerData.id || config.partnerData.id;
    }
  },
  computed: {
    checkoutComponent () {
      // Use the format 'checkout-partnerId'
      return `checkout-${this.partnerId() || 'anyrent'}`
    }
  }
}
