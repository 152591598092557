import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as types from './mutation-types'
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import OrderState from '../types/OrderState'
import { Order } from '../types/Order'
import { isOnline } from '@vue-storefront/core/lib/search'
import i18n from '@vue-storefront/i18n'
import { OrderService } from '@vue-storefront/core/data-resolver'
import { sha3_224 } from 'js-sha3'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { Logger } from '@vue-storefront/core/lib/logger'
import config from 'config'
import { orderHooksExecutors } from '../hooks'
import * as entities from '@vue-storefront/core/lib/store/entities'
import { prepareOrder, optimizeOrder, notifications } from './../helpers'

import { CartService } from '@vue-storefront/core/data-resolver'

const actions: ActionTree<OrderState, RootState> = {
  /**
   * Place order - send it to service worker queue
   * @param {Object} commit method
   * @param {Order} order order data to be send
   */
  async placeOrder ({ commit, getters, dispatch }, newOrder: Order) {
    // Check if order is already processed/processing
    const optimizedOrder = optimizeOrder(newOrder)
    const currentOrderHash = sha3_224(JSON.stringify(optimizedOrder))

    // FIXME When we know if validating hash is necessary enable this to stop reprocessing
    const isAlreadyProcessed = getters.getSessionOrderHashes.includes(currentOrderHash)
    if (isAlreadyProcessed) {
      console.error('INFO ABOUT HASHING ORDER WHEN IT SHOULD BE ALREADY PROCESSED: ', {...newOrder, currentOrderHash})
      // EventBus.$emit('setup-gopay-form-failed', i18n.t('There was a problem with initiating the payment, please refresh the page.'))
      // return
    }
    commit(types.ORDER_ADD_SESSION_STAMPS, newOrder)
    commit(types.ORDER_ADD_SESSION_ORDER_HASH, currentOrderHash)
    const preparedOrder = prepareOrder(optimizedOrder)

    EventBus.$emit('order-before-placed', { order: preparedOrder })
    const order = orderHooksExecutors.beforePlaceOrder(preparedOrder)

    if (!isOnline()) {
      dispatch('enqueueOrder', { newOrder: order })
      EventBus.$emit('order-after-placed', { order })
      orderHooksExecutors.beforePlaceOrder({ order, task: { resultCode: 200 } })
      return { resultCode: 200 }
    }
    try {
      EventBus.$emit('notification-progress-start', i18n.t('Processing order...'))
    } catch (err) {
      console.log('spinner fail in Order action')
      throw err
    }

    try {
      return dispatch('processOrder', { newOrder: order, currentOrderHash })
    } catch (error) {
      dispatch('handlePlacingOrderFailed', { newOrder: order, currentOrderHash })
      throw error
    }
  },
  async processOrder ({ commit, rootGetters, dispatch }, { newOrder, currentOrderHash }) {
    const order = { ...newOrder, transmited: true }
    const task = await OrderService.placeOrder(order)

    if (task.resultCode === 200) {
      const totalPriceObject = rootGetters['cart/getTotals'].find(total => total.code === 'grand_total')
      if (!totalPriceObject || !totalPriceObject.value) {
        console.error('Total price is not defined')
      }
      // inject and init the gopay payment
      // has to happen only after order has been placed
      if (newOrder.addressInformation.payment_method_code === 'gopay') {
        const userToken = rootGetters['user/getToken']
        const cartId = rootGetters['cart/getCartToken']

        const { result, code } = await CartService.startPayment(userToken, cartId)

        if (code === 200) {
          Logger.info(result, 'PAY')()
          EventBus.$emit('notification-progress-stop')
          EventBus.$emit('setup-gopay-form', result)
        } else {
          // EventBus.$emit('notification-progress-stop')
          Logger.error('error in payment init', 'PAY')()
          Logger.error(result, 'PAY')()
          EventBus.$emit('setup-gopay-form-failed', result)
          throw result
        }
      }

      dispatch('enqueueOrder', { newOrder: order })

      commit(types.ORDER_LAST_ORDER_WITH_CONFIRMATION, { order, confirmation: task.result })
      orderHooksExecutors.afterPlaceOrder({ order, task })
      EventBus.$emit('notification-progress-stop')
      EventBus.$emit('order-after-placed', { order, confirmation: task.result, totalPrice: totalPriceObject.value })

      return task
    }

    if (task.resultCode === 400) {
      commit(types.ORDER_REMOVE_SESSION_ORDER_HASH, currentOrderHash)

      Logger.error('Internal validation error; Order entity is not compliant with the schema: ' + JSON.stringify(task.result), 'orders')()
      dispatch('notification/spawnNotification', notifications.internalValidationError(), { root: true })
      dispatch('enqueueOrder', { newOrder: order })
      EventBus.$emit('notification-progress-stop')
      return task
    }
    EventBus.$emit('notification-progress-stop')
    throw new Error('Unhandled place order request error')
  },
  async injectGoPay () {

  },
  handlePlacingOrderFailed ({ commit, dispatch }, { newOrder, currentOrderHash }) {
    const order = { newOrder, transmited: false }
    commit(types.ORDER_REMOVE_SESSION_ORDER_HASH, currentOrderHash)
    dispatch('notification/spawnNotification', notifications.orderCannotTransfered(), { root: true })
    dispatch('enqueueOrder', { newOrder: order })

    EventBus.$emit('notification-progress-stop')
  },
  enqueueOrder (context, { newOrder }) {
    const orderId = entities.uniqueEntityId(newOrder)
    const ordersCollection = StorageManager.get('orders')
    const order = {
      ...newOrder,
      order_id: orderId.toString(),
      created_at: new Date(),
      updated_at: new Date()
    }

    ordersCollection.setItem(orderId.toString(), order, (err, resp) => {
      if (err) Logger.error(err, 'orders')()

      if (!order.transmited) {
        EventBus.$emit('order/PROCESS_QUEUE', { config: config })
      }

      Logger.info('Order placed, orderId = ' + orderId, 'orders')()
    }).catch((reason) => Logger.error(reason, 'orders'))
  }
}

export default actions
